import React from "react"
import { graphql, Link } from "gatsby"
import marked from "marked"
import Container from "components/Container"
import Layout from "components/Layout"
import Title from "components/Title"
import TitleBlock from "components/Blocks/TitleBlock"
import Img from "gatsby-image/withIEPolyfill"
import styles from "./Careers.module.css"
import altStyles from "../templates/WhatWeDo.module.css"
import Blocks from "components/Blocks"

// eslint-disable-next-line
export const Career = (data) => (
  <li>
    <div className={styles.positionCardTop}>
      <div className={styles.positionMeta}>
        <h3>{data.frontmatter.title}</h3>
        <span className={styles.positionType}>{data.frontmatter.kind}</span>
        <span className={styles.positionLocation}>{data.frontmatter.location}</span>
      </div>
      <Link className={`${altStyles.bannerbutton} ${styles.positionLink}`} to={`/careers/${data.frontmatter.url}`}>Learn more</Link>
    </div>
    <div
      className={styles.positionDesc}
      dangerouslySetInnerHTML={{ __html: marked(data.frontmatter.desc || "") }}
    />
  </li>
)

// eslint-disable-next-line
export const CareersPage = ({ data, blogPosts, careers, ...props }) => (
  <Layout {...props} {...data} {...blogPosts}>
    <Container className={altStyles.wrapper}>
      <div className={styles.col}>
        <Title className={`${altStyles.title} ${altStyles.titlesmall}`}>{data.title}</Title>
        <div className={altStyles.description} dangerouslySetInnerHTML={{ __html: marked(data.description || "") }} />
        {data.remote ? (
          <p className={styles.remote}>{data.remote}</p>
        ) : null}
        <a className={altStyles.bannerbutton} href={`${data.bannerButtonUrl}`} target="_blank" rel="noreferrer">{data.bannerButtonText}</a>
      </div>
      <div className={`${styles.col} ${altStyles.image}`}>
        {data.image ? (
          <Img
            loading="lazy"
            objectFit="contain"
            key={data.title}
            alt={data.title}
            title={data.title}
            fluid={data.image.childImageSharp.fluid}
          />
        ) : null}
      </div>
    </Container>
    
    <div className={styles.benefits}>
      <Container>
      <TitleBlock isLarger isWhite>{data.benefitsTitle}</TitleBlock>
        <ul>
          {data.benefits.map((benefit, i) => (
            <li key={i}>
              {benefit.icon && benefit.icon.extension === "svg" ? (
                <img alt={benefit.title} title={benefit.title} src={benefit.icon.publicURL} loading="lazy" width="80" height="80"/>
              ) : benefit.icon && benefit.icon.childImageSharp ? (
                <Img
                  loading="lazy"
                  objectFit="contain"
                  key={benefit.title}
                  alt={benefit.title}
                  title={benefit.title}
                  fluid={benefit.icon.childImageSharp.fluid}
                />
              ) : null}
              <h3>{benefit.title}</h3>
            </li>
          ))}
        </ul>
      </Container>
    </div>

    {(data.blocks || []).map((block, i) => {
      const Elem = Blocks[block.type]
      return Elem ? <Elem key={i} {...block} {...blogPosts} /> : null }
    )}

    {data.contact ? (
      <Container
        className={styles.contact}
        dangerouslySetInnerHTML={{ __html: marked(data.contact) }}
      />
    ) : null}
  </Layout>
)

// eslint-disable-next-line
export const CareersPageWrapper = ({ data, ...props }) => (
  <CareersPage
    {...props}
    careers={data.careers.edges}
    data={data.markdownRemark.frontmatter}
    blogPosts={data.blogPosts}
  />
)

export default CareersPageWrapper

export const pageQuery = graphql`
  query CareersPageQuery {
    careers: allMarkdownRemark(filter: { frontmatter: { templateKey: { eq: "career" } } }) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            url
            kind
            location
            desc
            hidden
          }
        }
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "careers" } }) {
      frontmatter {
        seo {
          title
          description
          canonical
          image {
            extension
            publicURL
            childImageSharp {
              fixed(width: 1200, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
        title
        description
        bannerButtonUrl
        bannerButtonText
        image {
          childImageSharp {
            fluid(maxWidth: 700, quality: 95) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        remote
        benefitsTitle
        benefits {
          icon {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 120, quality: 95) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          title
        }
        blocks {
          title
          type
          bgColor
          image {
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 1200, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { 
        frontmatter: { templateKey: { eq: "blog" } },
        isFuture: { eq: false }
      }
      limit: 4
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            id
            url
            title
            tags
            description
            date(formatString: "MMM Do, YYYY")
            image {
              extension
              publicURL
              childImageSharp {
                fluid(maxWidth: 338) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
